import { ReactNode } from 'react';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import CheckCircle from '../icons/CheckCircle.svg?react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function NavigationMenuItem({
  icon,
  title,
  to,
  onClick,
}: {
  readonly icon: ReactNode;
  readonly title: string;
  readonly to: string;
  readonly onClick: () => void;
}) {
  const { pathname } = useLocation();
  const selected = pathname.startsWith(to);
  const navigate = useNavigate();

  return (
    <MenuItem
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        navigate(to);
        onClick();
      }}
      value={to}
      selected={selected}
      sx={{
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Stack direction="row" gap={2} width="100%" justifyContent="space-between">
        <Box
          sx={theme => ({
            color: selected ? theme.palette.common.black : theme.palette.grey[400],
            width: '16px',
            height: '16px',
            '& svg': {
              width: '100%',
              height: '100%',
            },
          })}
        >
          {icon}
        </Box>
        <Typography variant="body2" flexGrow={1}>
          {title}
        </Typography>
        {selected ? (
          <Box sx={{ ml: 'auto', display: 'grid', placeItems: 'center' }}>
            <CheckCircle />
          </Box>
        ) : null}
      </Stack>
    </MenuItem>
  );
}
