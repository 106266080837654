import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import { SetSiteLabelData, useSetSiteLabel } from 'api/site';
import { components } from 'openapi-types';
import { FormDialog } from '../base/dialogs/FormDialog';
import { TextField } from '../base/forms/TextField';

interface DomainLabelFormValue {
  label: string;
}

export function ChangeLabelDialog({
  site,
  onClose,
}: {
  readonly site: Pick<components['schemas']['Site'], 'id' | 'label' | 'domain'>;
  readonly onClose: () => unknown;
}) {
  const { t } = useTranslation();
  const setSiteLabel = useSetSiteLabel(site.id!);
  const methods = useForm<DomainLabelFormValue>({
    defaultValues: {
      label: site?.label ?? '',
    },
    mode: 'onChange',
  });
  const onSubmit = async (data: SetSiteLabelData) => {
    await setSiteLabel.mutateAsync({ ...data });

    onClose();
  };

  return (
    <FormDialog
      methods={methods}
      onSubmit={onSubmit}
      onClose={onClose}
      open={Boolean(site?.id)}
      title={
        <Stack gap={4}>
          <Typography variant="body1" fontWeight="600" color="greys.900">
            {t('change_site_label')}
          </Typography>
          <Typography variant="body2" color="greys.500">
            {t('change_site_label_description', { domain: site?.domain })}
          </Typography>
        </Stack>
      }
      submitText={t('change')}
      form={
        <Stack spacing={4} direction="column">
          <TextField
            name="label"
            label={t('site_label')}
            color="primary"
            rules={{ required: true }}
          />
        </Stack>
      }
    />
  );
}
