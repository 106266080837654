import { MouseEvent, ReactNode, useState } from 'react';
import {
  Box,
  Button,
  Breakpoint,
  Menu,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ChevronSortVertical from '../icons/ChevronSortVertical.svg?react';
import NavigationMenuItem from './NavigationMenuItem';
import { useTranslation } from 'react-i18next';
import CardTab from './CardTab';

export type NavigationItem = {
  readonly duotoneIcon: (props: { readonly selected: boolean }) => JSX.Element;
  readonly solidIcon: ReactNode;
  readonly title: string;
  readonly to: string;
};

type Props = {
  readonly items: NavigationItem[];
  readonly breakpoint?: Breakpoint;
};
export default function Navigation({ items, breakpoint = 'md' }: Props) {
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down(breakpoint));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const selectedNavigationItem = items.find(item => {
    return window.location.pathname.startsWith(item.to);
  });
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isSmallBreakpoint) {
    return (
      <>
        <Button
          variant="contained"
          color="tertiary"
          size="large"
          fullWidth
          id="mobile-navigation-button"
          aria-controls={open ? 'mobile-navigation-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={theme => ({
            justifyContent: 'left',
            color: theme.palette.greys[900],
            padding: theme.spacing(2.5),
            outline: open ? `1px solid ${theme.palette.primary.main}` : 'none',
          })}
        >
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box
              sx={{
                width: '16px',
                height: '16px',
                '& svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              {selectedNavigationItem?.solidIcon}
            </Box>
            <Typography
              variant="body1"
              fontWeight={600}
              sx={{
                flexGrow: 1,
                textAlign: 'left',
              }}
            >
              {selectedNavigationItem?.title ?? t('site_navigation')}
            </Typography>
            <Box color="greys.500" alignSelf="end">
              <ChevronSortVertical />
            </Box>
          </Stack>
        </Button>
        <Menu
          id="mobile-navigation-menu"
          aria-labelledby="mobile-navigation-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: theme => ({
                width: `calc(100vw - ${theme.spacing(8)})`,
              }),
            },
          }}
          sx={{
            marginTop: '50px',
            '&.MuiList-root': {
              paddingRight: '4px !important',
            },
          }}
        >
          {items.map(item => (
            <NavigationMenuItem
              onClick={() => setAnchorEl(null)}
              key={item.to}
              icon={item.solidIcon}
              title={item.title}
              to={item.to}
            />
          ))}
        </Menu>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: `repeat(${items.length}, 1fr)`,
      }}
      component="nav"
      aria-label={t('settings_navigation')}
    >
      {items.map(item => (
        <CardTab key={item.to} IconComponent={item.duotoneIcon} title={item.title} to={item.to} />
      ))}
    </Box>
  );
}
