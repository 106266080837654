import { useState } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { AccessLogRow, useAccessLogNew } from 'api/siteMetrics';
import { useSiteDetails } from 'api/site';
import { TableColumnType } from 'component/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { useTranslation } from 'react-i18next';
import { DeviceIpCell } from './DeviceIpCell';
import { LocationCell } from '../common/LocationCell';
import { Table } from 'component/base/Table';
import { TimeCell } from '../common/TimeCell';
import { URLCell } from '../common/URLCell';
import { AccessLogFilterMenu } from './AccessLogFilterMenu';
import { ACCESS_LOG_FILTER_KEYS, AccessLogFilterKey } from './common';
import { AccessLogFilterDialog } from './AccessLogFilterDialog';
import { DurationSelect } from '../../DurationSelect';
import { AccessLogAppliedFilters } from './AccessLogAppliedFilters';
import { EmptyState } from 'component/new_design/base/EmptyState';
import ClipboardText from 'component/new_design/icons/ClipboardText.svg?react';

interface AccessLogTableProps {
  readonly siteId: string;
}

export const AccessLogTable = ({ siteId }: AccessLogTableProps) => {
  const { t } = useTranslation();

  const siteDetails = useSiteDetails({ id: siteId });
  const tableState = usePaginatedListState(
    {
      filterKeys: ACCESS_LOG_FILTER_KEYS as unknown as AccessLogFilterKey[],
    },
    { filters: { duration: '1h' } }
  );

  const domain = siteDetails.data?.data.result?.domain;

  const { isLoading, data } = useAccessLogNew(siteId, domain, tableState);

  const [activeFilterDialog, setActiveFilterDialog] = useState<AccessLogFilterKey | null>(null);

  const columns: TableColumnType<AccessLogRow>[] = [
    {
      label: t('date'),
      key: 'timestamp',
      renderValue: event => <TimeCell value={event.timestamp} />,
      width: 2,
    },
    {
      label: `${t('device')} | IP`,
      key: 'ClientIP',
      renderValue: event => <DeviceIpCell event={event} />,
      width: 2,
    },
    {
      label: t('country'),
      key: 'ClientCountry',
      renderValue: event => <LocationCell code={event.ClientCountry} />,
      width: 2,
    },
    {
      label: 'URL',
      key: 'ClientRequestURI',
      renderValue: event =>
        domain ? (
          <URLCell
            url={`https://${siteDetails.data?.data.result?.domain}/${event.ClientRequestURI}`}
          />
        ) : (
          <Skeleton variant="text" width="100%" />
        ),
      minWidth: '5em',
      width: 2,
    },
    {
      label: t('cache'),
      key: 'CacheCacheStatus',
    },
    {
      label: t('status_code'),
      key: 'OriginResponseStatus',
      width: 0,
    },
  ];

  const noDataTitle =
    tableState.params.filters.duration === '30d'
      ? t('no_data_yet')
      : t('no_results_during_this_timeframe');

  return (
    <Table
      title={t('access_log')}
      label={t('access_log')}
      columns={columns}
      data={data?.data.result || []}
      totalRowCount={data?.data.metadata?.total || 0}
      isLoading={isLoading}
      state={tableState}
      searchPlaceholder={t('search_logs')}
      enableSearch
      enablePagination
      renderExpandedRow={event => (
        <Typography
          variant="body2"
          sx={{
            display: 'grid',
            gridColumn: '1 / -1',
            gridTemplateColumns: 'subgrid',
            dt: { gridColumn: '1 / 3', fontWeight: 600, py: 4 },
            dd: { gridColumn: '3 / -1', py: 4 },
          }}
          component="dl"
        >
          <dt>Ray ID</dt>
          <dd>{event.RayID}</dd>
          <dt>{t('method')}</dt>
          <dd>{event.ClientRequestMethod}</dd>
          <dt>{t('host')}</dt>
          <dd>{event.ClientRequestHost}</dd>
          <dt>{t('path')}</dt>
          <dd>{event.ClientRequestURI}</dd>
          <dt>{t('user_agent')}</dt>
          <dd>{event.ClientRequestUserAgent}</dd>
        </Typography>
      )}
      tableBar={
        <AccessLogAppliedFilters
          state={tableState}
          onFilterOpen={setActiveFilterDialog}
          totalResults={data?.data.metadata.total || 0}
        />
      }
      rowIdKey="RayID"
      emptyState={
        <EmptyState
          icon={<ClipboardText />}
          title={noDataTitle}
          action={
            <DurationSelect
              value={tableState.params.filters.duration}
              onChange={d => tableState.setFilter('duration', d)}
            />
          }
        />
      }
    >
      <DurationSelect
        value={tableState.params.filters.duration}
        onChange={d => tableState.setFilter('duration', d)}
      />
      <AccessLogFilterMenu onFilterOpen={setActiveFilterDialog} />
      {activeFilterDialog ? (
        <AccessLogFilterDialog
          state={tableState}
          onClose={() => setActiveFilterDialog(null)}
          filterKey={activeFilterDialog}
        />
      ) : null}
    </Table>
  );
};
