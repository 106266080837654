import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table as MuiTable,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import { TableColumnType, TableRowType, TableRowActionType } from './types';
import { TableRowActions, actionsColumn } from './TableRowActions';
import { TableSkeletonRows } from './TableSkeletonRows';
import { TableHead } from './TableHead';
import { useTranslation } from 'react-i18next';
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { PaginationControls } from 'component/new_design/base/PaginationControls';
import { TableRowWrapper } from './TableRowWrapper';
import { ExpandedRowWrapper } from './ExpandedRowWrapper';
import { useDebouncedInputProps } from 'component/hooks/useDebouncedInput';
import { SearchInput } from 'component/new_design/base/SearchInput';

interface Props<T extends TableRowType> {
  readonly headerComponent?: ReactNode;
  readonly title: ReactNode;
  readonly label?: string;
  readonly description?: ReactNode;
  readonly isLoading: boolean;
  readonly data: T[];
  readonly totalRowCount: number;
  readonly state: PaginatedListState;
  readonly rowActions?: (TableRowActionType<T> | null)[];
  readonly columns: (TableColumnType<T> | null)[];
  readonly enableSearch?: boolean;
  readonly enablePagination?: boolean;
  readonly tableBar?: ReactNode;
  readonly children?: ReactNode;
  readonly renderExpandedRow?: (row: T) => ReactNode;
  readonly searchPlaceholder?: string;
  readonly rowIdKey?: keyof T;
  readonly emptyState: ReactNode;
}

type DataState = 'loading' | 'noResults' | 'noData' | 'hasData';

export const Table = <T extends TableRowType>({
  headerComponent,
  title,
  label,
  description,
  isLoading,
  data,
  totalRowCount,
  state,
  rowActions,
  columns,
  enableSearch = false,
  enablePagination = true,
  tableBar,
  children,
  renderExpandedRow,
  searchPlaceholder = '',
  rowIdKey = 'id',
  emptyState,
}: Props<T>) => {
  const { t } = useTranslation();

  const [expandedRowId, setExpandedRowId] = useState<string | number | null>(null);

  const { params, setSort, setSearch } = state;

  const searchInputProps = useDebouncedInputProps(params.search, setSearch);

  useEffect(() => {
    setExpandedRowId(null);
  }, [params]);

  const nonEmptyColumns = useMemo(() => columns.filter(Boolean) as TableColumnType<T>[], [columns]);

  const showActionsColumn = !!rowActions?.length;

  const dataState: DataState = useMemo(() => {
    if (isLoading) {
      return 'loading';
    } else if (
      !data.length &&
      (params.search || Object.keys(params.filters).filter(filter => filter !== 'duration').length)
    ) {
      return 'noResults';
    } else if (!data.length) {
      return 'noData';
    } else {
      return 'hasData';
    }
  }, [data, isLoading, params]);

  const getCellContent = (row: T, column: NonNullable<TableColumnType<T>>) => {
    if (column.renderValue) {
      return column.renderValue(row);
    } else if (column.key) {
      return String(row[column.key]);
    } else {
      return '-';
    }
  };

  const gridTemplateColumns = useMemo(
    () =>
      ([...nonEmptyColumns, showActionsColumn ? actionsColumn : null] as TableColumnType<T>[])
        .filter(Boolean)
        .map(({ width, minWidth }) => `minmax(${minWidth || 'max-content'}, ${width ?? 1}fr)`)
        .join(' '),
    [nonEmptyColumns, showActionsColumn]
  );

  return (
    <Card>
      <Stack direction="row" alignItems="center">
        {headerComponent ? (
          <Box sx={{ pl: 4, pt: 4, display: 'grid', placeItems: 'center' }}>{headerComponent}</Box>
        ) : null}
        <CardHeader
          title={title}
          subheader={description}
          action={
            dataState === 'noData' ? null : (
              <>
                {enableSearch ? (
                  <SearchInput {...searchInputProps} placeholder={searchPlaceholder} />
                ) : null}
                {children}
              </>
            )
          }
        />
      </Stack>

      <CardContent sx={dataState === 'noData' ? { height: '300px' } : null}>
        {dataState === 'noData' ? (
          emptyState
        ) : (
          <>
            {tableBar}

            <TableContainer>
              <MuiTable
                aria-label={label}
                sx={{ gridTemplateColumns }}
                component="div"
                role="table"
              >
                <TableHead
                  columns={nonEmptyColumns}
                  hasActionsColumn={showActionsColumn}
                  sortBy={params.sortBy}
                  sortDirection={params.sortDirection}
                  onSort={setSort}
                />
                <TableBody component="div" role="rowgroup">
                  {isLoading ? (
                    <TableSkeletonRows
                      columns={nonEmptyColumns}
                      perPage={params.perPage}
                      hasActionsColumn={showActionsColumn}
                    />
                  ) : (
                    data.map(row => (
                      <Fragment key={row[rowIdKey]}>
                        <TableRowWrapper
                          onToggleExpanded={() =>
                            setExpandedRowId(row[rowIdKey] === expandedRowId ? null : row[rowIdKey])
                          }
                          isExpanded={row[rowIdKey] === expandedRowId}
                          enableExpandableRows={!!renderExpandedRow}
                        >
                          <TableRow component="div" role="row">
                            {nonEmptyColumns.map(column => (
                              <TableCell
                                key={column.label}
                                align={column.align || 'left'}
                                component="div"
                                role="cell"
                              >
                                {getCellContent(row, column)}
                              </TableCell>
                            ))}
                            {showActionsColumn ? (
                              <TableCell align="right" component="div" role="cell">
                                <TableRowActions row={row} actions={rowActions} />
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableRowWrapper>
                        {!!renderExpandedRow && row[rowIdKey] === expandedRowId ? (
                          <ExpandedRowWrapper>{renderExpandedRow(row)}</ExpandedRowWrapper>
                        ) : null}
                      </Fragment>
                    ))
                  )}
                </TableBody>
              </MuiTable>

              {dataState == 'noResults' ? (
                <Typography my={10} textAlign="center">
                  {t('no_results_found')}
                </Typography>
              ) : null}
            </TableContainer>
          </>
        )}
      </CardContent>

      {enablePagination ? (
        <CardActions>
          <PaginationControls state={state} totalRowCount={totalRowCount} />
        </CardActions>
      ) : null}
    </Card>
  );
};
